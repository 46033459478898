<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath"></use>
  </svg>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
});

// COMPUTED
const iconPath = computed(() => {
  let icon = require(`@/assets/icons/${props.name}.svg`);
  if (Object.prototype.hasOwnProperty.call(icon, "default")) {
    icon = icon.default;
  }

  return icon.url;
});
const className = computed(() => `svg-icon svg-icon--${props.name}`);
</script>
