<template>
  <svg
    width="40"
    height="82"
    viewBox="0 0 40 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 2L20 79"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M1.99983 61.0086L19.9912 79L37.9826 61.0086"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowBtmLongIcon",
};
</script>

<style scoped></style>
