<template>
  <svg
    width="288"
    height="319"
    viewBox="0 0 288 319"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 80.6339L144 1.71468L286.5 80.6339V238.366L144 317.285L1.5 238.366V80.6339Z"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="144" cy="160" r="92.5" stroke="#1C59C6" stroke-width="3" />
    <path
      d="M143.023 161.872L166.726 147.904V119.968L143.023 106L119.319 119.968V147.904L143.023 161.872ZM143.023 161.872V135.682M143.023 135.682L120.784 121.714M143.023 135.682L165.261 121.714"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M110.703 213.583L134.407 199.615V171.679L110.703 157.71L87 171.679V199.615L110.703 213.583ZM110.703 213.583V187.393M110.703 187.393L88.465 173.425M110.703 187.393L132.942 173.425"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M178.215 213.583L201.918 199.615V171.679L178.215 157.71L154.511 171.679V199.615L178.215 213.583ZM178.215 213.583V187.393M178.215 187.393L155.976 173.425M178.215 187.393L200.453 173.425"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WhatDoingGausIcon",
};
</script>

<style scoped></style>
