<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4827 32.282C17.628 32.282 19.3672 30.5428 19.3672 28.3975C19.3672 26.2521 17.628 24.5129 15.4827 24.5129C13.3373 24.5129 11.5981 26.2521 11.5981 28.3975C11.5981 30.5428 13.3373 32.282 15.4827 32.282Z"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M41.0513 39.8127C41.0513 37.8366 41.8362 35.9415 43.2335 34.5443C44.6307 33.147 46.5259 32.3621 48.5019 32.3621C50.492 32.3663 52.3992 33.1598 53.805 34.5686C55.2107 35.9773 56.0002 37.8862 56.0002 39.8763"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M8 39.8127C8 37.8394 8.78276 35.9467 10.1766 34.5499C11.5704 33.1531 13.4614 32.3663 15.4347 32.3621C17.4276 32.3621 19.3389 33.1537 20.7481 34.5629C22.1573 35.9721 22.949 37.8834 22.949 39.8763"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M48.5329 32.282C50.6783 32.282 52.4175 30.5428 52.4175 28.3975C52.4175 26.2521 50.6783 24.5129 48.5329 24.5129C46.3876 24.5129 44.6484 26.2521 44.6484 28.3975C44.6484 30.5428 46.3876 32.282 48.5329 32.282Z"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M39.4739 27.2833C39.4739 26.2966 39.2795 25.3194 38.9019 24.4077C38.5243 23.4961 37.9707 22.6677 37.273 21.9699C36.5752 21.2721 35.7469 20.7187 34.8352 20.341C33.9235 19.9634 32.9464 19.769 31.9596 19.769V19.769C30.9819 19.769 30.0139 19.9619 29.1108 20.3365C28.2077 20.7111 27.3874 21.2602 26.6968 21.9522C26.0063 22.6443 25.459 23.4658 25.0863 24.3696C24.7136 25.2735 24.5228 26.242 24.5249 27.2197"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M32.008 19.769C34.1534 19.769 35.8926 18.0299 35.8926 15.8845C35.8926 13.7392 34.1534 12 32.008 12C29.8627 12 28.1235 13.7392 28.1235 15.8845C28.1235 18.0299 29.8627 19.769 32.008 19.769Z"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M39.4739 51.4657C39.4739 50.4789 39.2795 49.5018 38.9019 48.5901C38.5243 47.6784 37.9707 46.8501 37.273 46.1523C36.5752 45.4545 35.7469 44.901 34.8352 44.5234C33.9235 44.1458 32.9464 43.9514 31.9596 43.9514V43.9514C29.9863 43.9556 28.0953 44.7425 26.7015 46.1393C25.3077 47.5361 24.5249 49.4288 24.5249 51.402"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M32.008 43.9519C34.1534 43.9519 35.8926 42.2127 35.8926 40.0674C35.8926 37.922 34.1534 36.1829 32.008 36.1829C29.8627 36.1829 28.1235 37.922 28.1235 40.0674C28.1235 42.2127 29.8627 43.9519 32.008 43.9519Z"
      stroke="#222222"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <rect
      x="1"
      y="1"
      width="62"
      height="62"
      rx="10"
      stroke="#222222"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "PeopleIcon",
};
</script>

<style scoped></style>
