<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path d="M21 3L3 21" stroke="#888888" stroke-width="2" />
    <path d="M3 3L21 21" stroke="#888888" stroke-width="2" />
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>

<style scoped></style>
