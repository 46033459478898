const english = {
  form: {
    title: "反馈表",
    firstName: "姓名：",
    firstNamePlaceholder: "输入你的名字",
    email: "电子邮件地址",
    emailPlaceholder: "输入你的电子邮箱",
    message: "您的留言",
    messagePlaceholder: "输入您的内容",
  },
  words: {
    send: "发送",
    ok: "好的",
    complete: "成功地",
    from: "美元起",
    notRequired: "不需要",
    high: "安全系数高",
    copyright: "Copyright",
  },
  promo: {
    title:
      "专业的云算力提供商<br>  为企业提供更低成本、<br>  更高效的数据处理能力",
  },
  percentage: {
    title: "截止到2023年, 全球产生的数字化信息的总量为50ZB字节",
  },
  chat: {
    title: "聊天机器人软件GPT-4版本",
    subtitle: `我们预计, 训练 <span>聊天机器人GPT-4
        需要消耗 10^20 PFLOPS**</span> 的额外算力`,
    items: {
      expedia: {
        title: "Expedia",
        description:
          "为您提供更周全详细的旅行计划，制定详细的行程安排，出行安排，酒店安排，让您更方便快捷",
      },
      fiscal: {
        title: "FiscalNote",
        description:
          "提供并启用精选市场领先的实时数据集，用于法律，政治和监管数据和信息",
      },
      instacart: {
        title: "Instacart",
        description: "从您最喜欢的本地杂货店订购",
      },
      kayak: {
        title: "KAYAK",
        description:
          "搜索航班，住宿和租车。获得有关您可以在预算范围内去哪些地方的推荐",
      },
      klarna: {
        title: "Klarna Shopping",
        description: "从数千个在线商店搜索和比较价格",
      },
      milo: {
        title: "Milo Family AI",
        description:
          "赋予父母超能力，每天20分钟将忙碌变为魔术。问：嘿，Milo，今天有什么魔术？",
      },
      openTable: {
        title: "Open Table",
        description: "提供餐厅推荐，并直接链接预订",
      },
      shop: {
        title: "Shop",
        description: "从世界上最伟大的品牌中搜索数百万产品",
      },
      speak: {
        title: "Speak",
        description: "您的AI-powered语言导师，学会如何用另一种语言说任何事情",
      },
      wolfram: {
        title: "Wolfram",
        description: `Wolfram Alpha和Wolfram Language访问计算，数学，精选知识和实时数据`,
      },
      zapier: {
        title: "Zapier",
        description:
          "与超过 5000 个应用程序交互，如 Google Sheets，Trello，Gmail，HubSpot，Salesforce 等等",
      },
    },
  },
  jobs: {
    title: `<span>会有越来越多的工作，会被ChatGPT所取代</span> 这是必然的趋势`,
    description: `<span>GPT-4 需要更多更充足的算力资源，</span> 实现完成它的学习进化和迭代`,
    items: {
      "Data Entry Clerk": "数据录入员",
      "Customer Service Representative": "客户服务代表 ",
      Proofreader: "校对员",
      "Social Media Manager": "社交媒体经理 ",
      Bookkeeper: "会计员",
      Translator: "译员",
      Copywriter: "文案人员",
      "Market Research Analyst": "市场调研分析师",
      "Appointment Scheduler": "计划工程师",
      Telemarketer: "电话销售",
      "Technical Support Analyst": "技术支持分析师",
      "Email Marketer": "电子邮件广告商",
      Transcriptionist: "转录员",
      "News Reporter": "新闻记者",
      "Travel Agent": "旅行代理商",
      Paralegal: "律师助理",
      Recruiter: "猎头",
      "Content Moderator": "网络审查员 ",
      Assistant: "助理",
      Tutor: "家庭教师",
    },
  },
  whatDoing: {
    title: "我们的算力如何部署实现的呢？",
    users: "拥有未使用计算资源的用户",
    gaussBlockchain: "在 Kubernetes 上部署Gauss区块链",
    kubernetes: "大型Kubernetes网络",
    deploy: "设置网络共享",
  },
  solution: {
    title: "我们的解决方案",
    text: `提供一套全新的云算力技术，客户可随时随用，无需购买添加任何硬件设备，即可帮助企业对庞大数据进行处理和分析. <span>DEKUBE提供了远超其他全球产业巨头的数据保护能力。</span>`,
    kubernetes: `Kubernetes\n 平台`,
    gaussBlockchain: `Gauss\n 区块链`,
    sharingUnused: `共享未使用过的\n 计算能力`,
  },
  market: {
    title: "市场目标",
    description: `在市场上 <span>我们只是</span> 其中的一 <span>个小玩家</span>`,
    top: `<span>7%</span> 的市场（DEKUBE） `,
    bottom: "93% 的市场",
    bottomText: `
      Teradata, Oracle, SAP, EMC, Amazon, Microsoft, Google, VMware,
      Splunk, Alteryx, iTechArt, InData Labs, ScienceSoft, RightData ,
      Integrate.io, Oxagile, Innowise Group, IBM, HP Enterprise, Cogito
      and etc`,
  },
  comparing: {
    title: "与竞争对手相比",
    description: `由于GAUSS区块链和较低的基础设施支持成本，与竞争对手相比 <br>
        <span>DEKUBE的优势在于：更安全、更高效，成本更低</span>`,
    table: {
      titles: {
        amazon: "Amazon Elastic MapReduce",
        google: "Google Cloud Platform",
        microsoft: "Microsoft Azure Analytics Services",
      },
      security: "安全",
      uptime: "全运行时间的服<br />务等级协议 (SLA)",
      interoperability: "兼容所有领先的大数据分析技术",
      support: "硬件基础设施的支持",
      infrastructure: "基础设施支持成本<br /> (占所有成本的百分比)",
      endUser: "终端用户的服务成本<br/> (每单位每月)",
    },
  },
  achiev: {
    title: "DEKUBE 团队曾创造的成就",
    subtitle: `
        在过去的 20 年里，<span> 我们的团队</span> 为世界各地的客户 <span>开发和部署了系统</span>`,
    pastProjects: "过去的项目包括:",
    items: {
      corporate: "使用对称和非对称加密的混合密码算法传输企业防泄密信息系统",
      hierarchical: "拥有使用外部认证机构交叉证书能力的分层公钥基础设施系统",
      anonymization: "使用 ToR 和 I2P 进行数据传输的匿名系统",
      security: "以安全为实质的操作系统",
      distributed: "分布式网络信息安全管控体系",
      highLoad: "高端容错电子数据交换系统",
      overSecure: "用于企业网络的超安全用户密钥存储系统",
    },
  },
  modal: {
    confirm: "确认",
    share: "分享算力资源",
    connect: "连接到网络",
    topText: "DEKUBE 系统客户端将允许您连接到 DEKUBE 网络并提供您的计算资源",
    alert: "请下载文件并进行安装，请您按照界面提示进行操作",
    text: "客户端安装与任何其他程序一样。 在继续安装之前，请确保您具有管理员权限",
    btn: "下载文件",
    size: "文件大小",
    welcome: "欢迎使用DEKUBE!",
    description: "选择要安装 DEKUBE 客户端的操作系统",
    windowsDescr: "需要下载安装文件",
    linuxDescr: "将打开新选项卡并 使用命令行进行安装",
    publicKey:
      "您将需要 <a href='https://testnet.imgauss.com' target='_blank'>GAUSS</a>钱包公钥。",
  },
};
export default english;
