<template>
  <svg
    width="321"
    height="400"
    viewBox="0 0 321 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_425_5514)">
      <path
        d="M242.861 112.244L173.91 70.2993L69.8908 131.978L20 101.655L173.91 16L287.139 83.1231L242.861 112.244Z"
        fill="#3A97F2"
      />
      <path
        d="M20.094 102.006L20 282.086L173.768 375.661L173.693 318.859L69.3764 255.382L69.8839 132.291L20.094 102.006Z"
        fill="#0A347F"
      />
      <path
        d="M173.768 318.592L300.171 241.439V298.489L173.843 375.661L173.768 318.592Z"
        fill="#1C59C6"
      />
      <path
        d="M218.074 169.108L130.765 116.34V149.181L198.471 223.196L209.272 218.219L218.074 169.108Z"
        fill="#0A347F"
      />
      <path
        d="M251.955 251.864H198.074L193.315 217.983L203.832 224.383L251.955 251.864Z"
        fill="#0A347F"
      />
      <path
        d="M173.655 300.08L86.4595 247.136V143.706L173.768 196.668L173.655 300.08Z"
        fill="#0A347F"
      />
      <path
        d="M173.768 300.08L197.224 285.604V181.496L173.88 195.877L173.768 300.08Z"
        fill="#1C59C6"
      />
      <path
        d="M197.224 224.327L252.227 251.864L285.836 231.74L229.005 205.419L268.947 138.493L218.166 169.27L197.224 224.327Z"
        fill="#1C59C6"
      />
      <path
        d="M197.225 181.739L109.755 129.371L86.4595 143.537L173.741 195.83L197.225 181.739Z"
        fill="#3A97F2"
      />
      <path
        d="M218.101 169.768L268.896 138.867L181.24 86.3685L130.765 117.157L218.101 169.768Z"
        fill="#3A97F2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_425_5514"
        x="0"
        y="0"
        width="320.17"
        height="399.661"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_425_5514"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_425_5514"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LogoIcon",
};
</script>

<style scoped></style>
