<template>
  <svg
    width="288"
    height="319"
    viewBox="0 0 288 319"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M113.5 142.389L144 125.71L174.5 142.389V175.611L144 192.29L113.5 175.611V142.389Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M73.5 208.389L104 191.71L134.5 208.389V241.611L104 258.29L73.5 241.611V208.389Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M153.5 208.389L184 191.71L214.5 208.389V241.611L184 258.29L153.5 241.611V208.389Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M73.5 79.3893L104 62.7097L134.5 79.3893V112.611L104 129.29L73.5 112.611V79.3893Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M153.5 79.3893L184 62.7097L214.5 79.3893V112.611L184 129.29L153.5 112.611V79.3893Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M193.5 142.389L224 125.71L254.5 142.389V175.611L224 192.29L193.5 175.611V142.389Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.5 142.389L64 125.71L94.5 142.389V175.611L64 192.29L33.5 175.611V142.389Z"
      stroke="#1C59C6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.5 80.6339L144 1.71468L286.5 80.6339V238.366L144 317.285L1.5 238.366V80.6339Z"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "WhatDoingKuberIcon",
};
</script>

<style scoped></style>
