<template>
  <div class="m-comparing">
    <v-container>
      <div class="m-comparing__row">
        <div class="m-comparing__td">
          <LogoIcon class="m-comparing__td--icon" />
        </div>
        <div class="m-comparing__td">
          {{ $t("comparing.table.titles.amazon") }}
        </div>
        <div class="m-comparing__td">
          {{ $t("comparing.table.titles.google") }}
        </div>
        <div class="m-comparing__td">
          {{ $t("comparing.table.titles.microsoft") }}
        </div>
      </div>
      <div class="m-comparing__title">{{ $t(`comparing.table.security`) }}</div>
      <div class="m-comparing__row">
        <div class="m-comparing__td">{{ $t("words.high") }}</div>
        <div class="m-comparing__td">{{ $t("words.high") }}</div>
        <div class="m-comparing__td">{{ $t("words.high") }}</div>
        <div class="m-comparing__td">{{ $t("words.high") }}</div>
      </div>
      <div class="m-comparing__title" v-html="$t(`comparing.table.uptime`)" />
      <div class="m-comparing__row">
        <div class="m-comparing__td">> 99%</div>
        <div class="m-comparing__td">> 99%</div>
        <div class="m-comparing__td">> 99%</div>
        <div class="m-comparing__td">> 99%</div>
      </div>
      <div class="m-comparing__title">
        {{ $t("comparing.table.interoperability") }}
      </div>
      <div class="m-comparing__row">
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
      </div>
      <div class="m-comparing__title">{{ $t("comparing.table.support") }}</div>
      <div class="m-comparing__row">
        <div class="m-comparing__td m-comparing__td--hard">
          {{ $t("words.notRequired") }}
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
        <div class="m-comparing__td">
          <v-icon class="m-comparing__td--plus" name="plus" />
        </div>
      </div>
      <div
        class="m-comparing__title"
        v-html="$t(`comparing.table.infrastructure`)"
      />
      <div class="m-comparing__row">
        <div class="m-comparing__td m-comparing__td--hard">5%</div>
        <div class="m-comparing__td">80%</div>
        <div class="m-comparing__td">80%</div>
        <div class="m-comparing__td">80%</div>
      </div>
      <div class="m-comparing__title" v-html="$t(`comparing.table.endUser`)" />
      <div class="m-comparing__row">
        <div class="m-comparing__td m-comparing__td--hard">≥ $8</div>
        <div class="m-comparing__td">≥ $10</div>
        <div class="m-comparing__td">≥ $10</div>
        <div class="m-comparing__td">≥ $10</div>
      </div>
    </v-container>
  </div>
</template>

<script>
import LogoIcon from "@/components/ui-kit/icons/LogoIcon.vue";

export default {
  name: "mobile-comparing",
  components: { LogoIcon },
};
</script>

<style lang="scss" scoped>
.m-comparing {
  display: none;
  margin-top: 38px;
  @include below($notebook) {
    display: block;
  }
  .m-comparing__row:first-child .m-comparing__td:first-child {
    border-radius: 21px 21px 0 0;
  }
  .m-comparing__row:last-child .m-comparing__td:first-child {
    border-radius: 0 0 21px 21px;
  }
  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 4px;
    .m-comparing__td:first-child {
      background: #ffffff;
    }
  }
  &__title {
    padding: 4px 8px;
    background: #dadada;
    border-radius: 0 5px 5px 0;
    font-size: 12px;
    text-align: center;
  }
  &__td {
    font-size: 14px;
    text-align: center;
    padding: 16px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &--icon {
      width: 39.57px;
      height: 50.79px;
      margin: 0 auto;
    }
    &--plus {
      width: 16px;
      height: 16px;
    }
    &--hard {
      font-weight: 700;
    }
  }
}
</style>
