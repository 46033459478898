<template>
  <div class="jobs">
    <v-container>
      <h2 class="title" v-html="$t('jobs.title')" />
      <div class="jobs__content">
        <div class="jobs__item" v-for="(item, idx) in items" :key="idx">
          {{ item }}
        </div>
      </div>
      <div class="jobs__btm" v-html="$t('jobs.description')" />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "JobsBlock",
  computed: {
    items() {
      if (window.innerWidth <= 1024) {
        return [
          this.$t('jobs.items["Data Entry Clerk"]'),
          this.$t('jobs.items["Proofreader"]'),
          this.$t('jobs.items["Paralegal"]'),
          this.$t('jobs.items["Appointment Scheduler"]'),
          this.$t('jobs.items["Email Marketer"]'),
          this.$t('jobs.items["Market Research Analyst"]'),
          this.$t('jobs.items["News Reporter"]'),
          this.$t('jobs.items["Social Media Manager"]'),
          this.$t('jobs.items["Content Moderator"]'),
          this.$t('jobs.items["Technical Support Analyst"]'),
          this.$t('jobs.items["Telemarketer"]'),
          this.$t('jobs.items["Copywriter"]'),
          this.$t('jobs.items["Recruiter"]'),
          this.$t('jobs.items["Assistant"]'),
          this.$t('jobs.items["Transcriptionist"]'),
          this.$t('jobs.items["Travel Agent"]'),
          this.$t('jobs.items["Tutor"]'),
          this.$t('jobs.items["Customer Service Representative"]'),
          this.$t('jobs.items["Translator"]'),
          this.$t('jobs.items["Bookkeeper"]'),
        ];
      }
      return [
        this.$t('jobs.items["Data Entry Clerk"]'),
        this.$t('jobs.items["Customer Service Representative"]'),
        this.$t('jobs.items["Proofreader"]'),
        this.$t('jobs.items["Social Media Manager"]'),
        this.$t('jobs.items["Bookkeeper"]'),
        this.$t('jobs.items["Translator"]'),
        this.$t('jobs.items["Copywriter"]'),
        this.$t('jobs.items["Market Research Analyst"]'),
        this.$t('jobs.items["Appointment Scheduler"]'),
        this.$t('jobs.items["Telemarketer"]'),
        this.$t('jobs.items["Technical Support Analyst"]'),
        this.$t('jobs.items["Email Marketer"]'),
        this.$t('jobs.items["Transcriptionist"]'),
        this.$t('jobs.items["News Reporter"]'),
        this.$t('jobs.items["Travel Agent"]'),
        this.$t('jobs.items["Paralegal"]'),
        this.$t('jobs.items["Recruiter"]'),
        this.$t('jobs.items["Content Moderator"]'),
        this.$t('jobs.items["Assistant"]'),
        this.$t('jobs.items["Tutor"]'),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs {
  background: #f6f6f6;
  padding: 60px 0 44px;
  &__content {
    margin-top: 55px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    @include below($notebook) {
      column-gap: 10px;
      margin-top: 50px;
      justify-content: flex-start;
    }
  }
  &__item {
    padding: 5px 32px;
    height: 40px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #dfebff;
    border-radius: 30px;
    color: #202126;
    font-size: 14px;
    @include below($notebook) {
      font-size: 12px;
      padding: 5px 12px;
      height: 24px;
    }
  }
  &__btm {
    margin-top: 48px;
    font-size: 24px;
    &::v-deep(span) {
      color: theme(current-color);
    }
  }
}
</style>
