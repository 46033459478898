<template>
  <div class="comparing">
    <v-container>
      <h2 class="title">{{ $t("comparing.title") }}</h2>
      <p
        class="subtitle comparing__subtitle"
        v-html="$t(`comparing.description`)"
      />
    </v-container>
    <desktop-comparing />
    <mobile-comparing />
  </div>
</template>

<script>
import DesktopComparing from "@/components/_blocks/share/desktop-comaring.vue";
import MobileComparing from "@/components/_blocks/share/mobile-comparing.vue";

export default {
  name: "ComparingBlock",
  components: { MobileComparing, DesktopComparing },
};
</script>

<style lang="scss" scoped>
.comparing {
  padding: 100px 0 90px;
  @include below($notebook) {
    padding: 56px 0;
    background: #f1f1f1;
  }
  &__subtitle {
    margin-top: 33px;
    max-width: 720px;

    @include below($notebook) {
      margin-top: 24px;
    }
  }
}
</style>
