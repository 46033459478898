<template>
  <div class="promo">
    <ConnectModal v-model:open="openActiveModal" />
    <div class="promo__header">
      <v-icon class="promo__logo" name="logo-text" />
      <div class="promo__right-wrap">
        <a class="promo__btn" :href="CONSOLE_LINK" target="_blank">
          {{ $t("modal.connect") }}
        </a>
        <button class="promo__btn" @click="openActiveModal = true">
          {{ $t("modal.share") }}
        </button>
        <div
          class="promo__select"
          :class="{ active: isOpen }"
          @click.stop="isOpen = !isOpen"
        >
          {{ langList[currentLocale] }}
          <ArrowBtmIcon class="promo__select-icon" />
          <div class="promo__menu" v-show="isOpen">
            <div
              class="promo__menu-item"
              :class="{ active: currentLocale === 'en' }"
              @click="updateLang('en')"
            >
              English
            </div>
            <div
              class="promo__menu-item"
              :class="{ active: currentLocale === 'zh' }"
              @click="updateLang('zh')"
            >
              中文
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-container class="promo__wrap">
      <div class="promo__content">
        <PromoIcon class="promo__i" />
        <PromoMobileIcon class="promo__i--mobile" />
        <!--        <h2 class="promo__text" v-html="$t('promo.title')"></h2>-->
        <!--        <LogoIcon class="promo__icon" />-->
      </div>
      <ArrowBtmIcon class="promo__down" v-scroll-to="'#percentage'" />
    </v-container>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { CONSOLE_LINK } from "@/core/constants";
import ArrowBtmIcon from "@/components/ui-kit/icons/ArrowBtmIcon.vue";
import i18n, { currentLocale } from "@/core/i18n";
import PromoIcon from "@/components/ui-kit/icons/PromoIcon.vue";
import PromoMobileIcon from "@/components/ui-kit/icons/PromoMobileIcon.vue";
import ConnectModal from "@/components/modals/ConnectModal.vue";

const isOpen = ref(false);
const openActiveModal = ref(false);
const langList = {
  en: "Eng",
  zh: "中文",
};
onMounted(() => {
  window.addEventListener("click", (e) => {
    if (
      isOpen.value &&
      e.target !== document.querySelector(".promo__menu") &&
      !document.querySelector(".promo__menu").contains(e.target)
    )
      isOpen.value = false;
  });
});
const updateLang = (newLocale) => {
  i18n.locale = newLocale;
  localStorage.setItem("lang", newLocale);
  window.location.reload();
};
</script>

<style lang="scss" scoped>
.promo {
  padding: 26px 0 64px;
  background: radial-gradient(
    34.58% 47.71% at 67.42% 52.29%,
    #30313a 0%,
    #202126 100%
  );
  @include below($notebook) {
    height: 640px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 56px;
    @include below($tablet) {
      padding: 0 18px;
    }
  }
  &__logo {
    width: 160px;
    height: 44px;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 52px !important;
  }
  &__content {
    height: 100%;
    //display: flex;
    //align-items: center;
    //gap: 16px;
    //@include below($notebook) {
    //  flex-direction: column-reverse;
    //  justify-content: center;
    //}
  }
  &__text {
    width: 50%;
    font-weight: 500;
    font-size: 50px;
    color: theme(white);
    @include below($notebook) {
      font-weight: 400;
      font-size: 32px;
    }
    @include below($tablet) {
      width: 100%;
    }
  }
  &__icon {
    width: 50%;
    height: 360px;
    @include below($notebook) {
      width: 136.93px;
      height: 175.78px;
    }
  }
  &__down {
    width: 32px;
    height: 32px;
    margin: 0 auto;
    cursor: pointer;
    color: #3a97f2;
  }
  &__select {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: theme(white);
    cursor: pointer;
    padding: 7px 12px;
    border-radius: 8px;
    transition: background 0.2s ease;
    position: relative;
    &:hover {
      background: rgba(17, 18, 34, 0.1);
    }
    &.active {
      background: #222222;
      .promo__select-icon {
        transform: rotate(180deg);
      }
    }
    &-icon {
      width: 12px;
      height: 12px;
      transition: transform 0.2s ease;
    }
  }
  &__menu {
    position: absolute;
    bottom: -8px;
    transform: translateY(100%);
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #222222;
    border-radius: 8px;
    left: 0;
    color: #bdbcbc;
    font-size: 14px;
    box-shadow: 0px 0px 4px #19191b;
    &-item {
      padding: 7px 4px;
      transition: color 0.2s ease;
      border-radius: 8px;
      &.active {
        color: #3a97f2;
      }
      &:hover {
        color: #dadada;
      }
    }
  }
  &__i {
    height: 100%;
    width: 100%;
    @include below($notebook) {
      display: none;
    }
    &--mobile {
      margin: 0 auto;
      display: none;
      height: 100%;
      max-width: 100%;
      @include below($notebook) {
        display: block;
      }
    }
  }
  &__right-wrap {
    display: flex;
    align-items: center;
    @include below($tablet) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--blue-200, #3a97f2);
    font-weight: 500;
    padding: 8px 16px;
    font-size: 16px;
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid var(--blue-400, #1c59c6);
    min-height: 48px;
    background: transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    &:hover {
      background: var(--blue-400, #1c59c6);
      color: white;
    }
    @include below($tablet) {
      display: none;
    }
  }
}
</style>
