<template>
  <svg
    width="338"
    height="40"
    viewBox="0 0 338 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 19.9861L335 19.9861"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
    />
    <path
      d="M317.009 37.9826L335 19.9912L317.009 1.99983"
      stroke="#222222"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrayRightLongIcon",
};
</script>

<style scoped></style>
