<template>
  <div class="percentage" id="percentage">
    <v-container>
      <h2 class="title">{{ $t("percentage.title") }}</h2>
      <div class="percentage__graph">
        <PercentGraphIcon class="percentage__icon" />
        <PercentGraphMobileIcon class="percentage__icon--mobile" />
      </div>
    </v-container>
  </div>
</template>

<script>
import PercentGraphIcon from "@/components/ui-kit/icons/PercentGraphIcon.vue";
import PercentGraphMobileIcon from "@/components/ui-kit/icons/PercentGraphMobileIcon.vue";

export default {
  name: "PercentageBlock",
  components: { PercentGraphMobileIcon, PercentGraphIcon },
};
</script>

<style lang="scss" scoped>
.percentage {
  padding-top: 120px;
  padding-bottom: 20px;
  @include below($notebook) {
    padding-top: 70px;
  }
  .title {
    max-width: 720px;
  }
  &__graph {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    @include below($notebook) {
      margin-top: 50px;
      justify-content: flex-start;
    }
  }
  &__icon {
    height: 350px;
    width: 100%;
    @include below($notebook) {
      display: none;
    }
    &--mobile {
      display: none;
      height: 600px;
      @include below($notebook) {
        display: block;
      }
    }
  }
}
</style>
