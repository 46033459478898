<template>
  <div>
    <PromoBlock />
    <PercentageBlock />
    <ChatBlock />
    <JobsBlock />
    <WhatDoingBlock />
    <SolutionBlock />
    <MarketBlock />
    <ComparingBlock />
    <AchievBlock />
    <FooterBlock />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { MONITORING_LINK, CONSOLE_LINK } from "@/core/constants";
import PromoBlock from "@/components/_blocks/promo.vue";
import PercentageBlock from "@/components/_blocks/percentage.vue";
import ChatBlock from "@/components/_blocks/chat.vue";
import JobsBlock from "@/components/_blocks/jobs.vue";
import SolutionBlock from "@/components/_blocks/solution.vue";
import WhatDoingBlock from "@/components/_blocks/whatDoing.vue";
import FooterBlock from "@/components/_blocks/footer.vue";
import AchievBlock from "@/components/_blocks/achiev.vue";
import MarketBlock from "@/components/_blocks/market.vue";
import ComparingBlock from "@/components/_blocks/comparing.vue";

export default defineComponent({
  components: {
    ComparingBlock,
    MarketBlock,
    AchievBlock,
    FooterBlock,
    WhatDoingBlock,
    SolutionBlock,
    JobsBlock,
    ChatBlock,
    PercentageBlock,
    PromoBlock,
  },
  setup() {
    if (["/console", "/console/"].includes(window.location.pathname))
      window.location.href = CONSOLE_LINK;
    if (["/monitoring", "/monitoring/"].includes(window.location.pathname))
      window.location.href = MONITORING_LINK;
  },
});
</script>

<style lang="scss"></style>
