<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="33"
    viewBox="0 0 72 33"
    fill="none"
  >
    <path
      d="M57 26.8333C57 29.6868 54.7055 32 51.875 32H21.125C18.2945 32 16 29.6868 16 26.8333V6.16667C16 3.3132 18.2945 1 21.125 1H51.875C54.7055 1 57 3.3132 57 6.16667M17.0751 3L35.0414 15.482C35.9189 16.0944 37.0811 16.0944 37.9586 15.482L55.5 3"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M68 23L55 10"
      stroke="#1C59C6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55 23L68 10"
      stroke="#1C59C6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ErrorIcon",
};
</script>

<style scoped></style>
