<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="139"
    height="95"
    viewBox="0 0 139 95"
    fill="none"
  >
    <path
      d="M83.4395 17.6572L71.4969 10.3921L53.4802 21.0751L44.8389 15.8231L71.4969 0.987228L91.1087 12.6133L83.4395 17.6572Z"
      fill="#3A97F2"
    />
    <path
      d="M44.8551 15.8838L44.8389 47.0745L71.4722 63.2821L71.4592 53.4439L53.3911 42.4493L53.479 21.1293L44.8551 15.8838Z"
      fill="#0A347F"
    />
    <path
      d="M71.4727 53.3975L93.3662 40.0343V49.9155L71.4857 63.282L71.4727 53.3975Z"
      fill="#1C59C6"
    />
    <path
      d="M79.1458 27.5063L64.0234 18.3666V24.0548L75.7504 36.8745L77.6211 36.0125L79.1458 27.5063Z"
      fill="#0A347F"
    />
    <path
      d="M85.0152 41.84H75.6826L74.8584 35.9717L76.68 37.0801L85.0152 41.84Z"
      fill="#0A347F"
    />
    <path
      d="M71.4524 50.1912L56.3496 41.0211V23.1064L71.4719 32.2798L71.4524 50.1912Z"
      fill="#0A347F"
    />
    <path
      d="M71.4727 50.1911L75.5354 47.6839V29.6518L71.4921 32.1427L71.4727 50.1911Z"
      fill="#1C59C6"
    />
    <path
      d="M75.5352 37.0701L85.062 41.8398L90.8832 38.3541L81.0397 33.7952L87.9579 22.2033L79.1624 27.534L75.5352 37.0701Z"
      fill="#1C59C6"
    />
    <path
      d="M75.5347 29.6938L60.3844 20.6236L56.3496 23.0772L71.4672 32.1346L75.5347 29.6938Z"
      fill="#3A97F2"
    />
    <path
      d="M79.1504 27.6203L87.9483 22.2681L72.7659 13.1751L64.0234 18.5078L79.1504 27.6203Z"
      fill="#3A97F2"
    />
    <path
      d="M18.8347 68.6232H0V94.7731H18.8347L21.9782 92.1584V71.2313L18.8347 68.6232ZM15.688 89.5405H6.27716V73.8558H15.688V89.5405Z"
      fill="#222222"
    />
    <path
      d="M43.9578 73.8558V68.6232H25.123V94.7731H43.9578V89.5405H31.4002V84.3145H43.9578V79.0819H31.4002V73.8558H43.9578Z"
      fill="#222222"
    />
    <path
      d="M68.7782 68.7274H62.0473L53.0771 81.8039L62.0473 94.8773H68.7782L59.808 81.8039L68.7782 68.7274Z"
      fill="#222222"
    />
    <path
      d="M47.2734 94.8774H55.4732L47.2734 81.7976V94.8774Z"
      fill="#1C59C6"
    />
    <path
      d="M47.0762 68.6232V81.6933L55.3119 68.6232H47.0762Z"
      fill="#1C59C6"
    />
    <path
      d="M86.8062 68.7274V89.6447H77.3953V68.7274H71.1182V92.2626L74.2519 94.8773H89.9529L93.0964 92.2626V68.7274H86.8062Z"
      fill="#222222"
    />
    <path
      d="M117.413 71.3355L114.269 68.7274H95.4346V94.8773H114.269L117.413 92.2626V84.4186L114.269 81.8039L117.413 79.186V71.3355ZM111.126 89.6447H101.715V84.4186H111.126V89.6447ZM111.126 79.186H101.715V73.9599H111.126V79.186Z"
      fill="#222222"
    />
    <path
      d="M138.591 73.9567V68.7241H119.756V94.8741H138.591V89.6415H126.033V84.4154H138.591V79.1828H126.033V73.9567H138.591Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: "DarkLogoIcon",
};
</script>

<style scoped></style>
