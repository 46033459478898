<template>
  <div class="market">
    <v-container>
      <h2 class="title">{{ $t("market.title") }}</h2>
      <p class="subtitle" v-html="$t(`market.description`)" />
      <div class="market__content">
        <div class="market__icon-wrap">
          <v-icon class="market__icon" name="pyramid" />
          <div class="market__top" v-html="$t(`market.top`)" />
          <div class="market__btm">
            <div class="market__btm-title">{{ $t(`market.bottom`) }}</div>
            <div class="market__btm-subtitle">
              {{ $t(`market.bottomText`) }}
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MarketBlock",
};
</script>

<style lang="scss" scoped>
.market {
  padding: 110px 0 16px;
  @include below($notebook) {
    padding-top: 48px;
    padding-bottom: 170px;
  }
  .subtitle {
    margin-top: 33px;
    @include below($notebook) {
      margin-top: 24px;
    }
  }
  &__content {
    margin-top: 86px;
    padding: 0 40px;
    @include below($notebook) {
      padding: 0 9px;
      margin-top: 60px;
    }
  }
  &__icon-wrap {
    position: relative;
    width: 50%;
    @include below($notebook) {
      width: 126.88px;
    }
  }
  &__icon {
    width: 100%;
    height: 388px;
    @include below($notebook) {
      height: 124px;
    }
  }
  &__top {
    position: absolute;
    top: 10px;
    right: -25px;
    transform: translateX(100%);
    font-weight: 600;
    font-size: 40px;
    color: #222222;
    &::v-deep(span) {
      color: theme(current-color);
    }
    &:lang(zh) {
      top: 35px;
      @include below($notebook) {
        top: 20px;
      }
    }
    @include below($notebook) {
      font-size: 14px;
    }
  }
  &__btm {
    position: absolute;
    bottom: 30px;
    right: -25px;
    transform: translateX(100%);
    color: #222222;
    @include below($notebook) {
      transform: translateX(100%) translateY(70%);
      bottom: 16px;
    }
    &-title {
      font-weight: 600;
      font-size: 40px;
      @include below($notebook) {
        font-size: 14px;
      }
    }
    &-subtitle {
      font-size: 18px;
      margin-top: 20px;
      width: 541px;
      @include below($notebook) {
        font-size: 14px;
        width: 192px;
        margin-top: 10px;
        color: #626575;
      }
    }
  }
}
</style>
