import { createApp } from "vue";
import App from "./App.vue";
import VContainer from "@/components/ui-kit/v-container.vue";
import VImg from "@/components/ui-kit/v-img.vue";
import VIcon from "@/components/ui-kit/v-icon.vue";
import VueScrollTo from "vue-scrollto";
import i18n from "./core/i18n";

// GLOBAL CSS
import "@/assets/scss/main.scss";

const app = createApp(App);

app
  .use(i18n)
  .use(VueScrollTo)
  .component("v-container", VContainer)
  .component("v-img", VImg)
  .component("v-icon", VIcon)
  .mount("#app");
