<template>
  <div class="achiev">
    <v-container>
      <h2 class="title">{{ $t("achiev.title") }}</h2>
      <p class="subtitle achiev__subtitle" v-html="$t(`achiev.subtitle`)" />
      <div class="achiev__past">{{ $t(`achiev.pastProjects`) }}</div>
      <div class="achiev__items">
        <div
          class="achiev__item"
          v-for="(item, idx) in items"
          :key="idx"
          :class="{ [item.class]: item.class }"
        >
          <v-icon class="achiev__item-icon" :name="item.icon" />
          {{ item.label }}
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AchievBlock",
  data() {
    return {
      items: [
        {
          icon: "code",
          class: "half",
          label: this.$t("achiev.items.corporate"),
        },
        {
          icon: "system",
          class: "half",
          label: this.$t("achiev.items.hierarchical"),
        },
        {
          icon: "anonym",
          label: this.$t("achiev.items.anonymization"),
        },
        {
          icon: "lock",
          label: this.$t("achiev.items.security"),
        },
        {
          icon: "cube",
          label: this.$t("achiev.items.distributed"),
        },
        {
          icon: "cube-check",
          label: this.$t("achiev.items.highLoad"),
        },
        {
          icon: "cube-lock",
          label: this.$t("achiev.items.overSecure"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.achiev {
  padding: 40px 0;
  background: #f6f6f6;
  @include below($notebook) {
    padding: 70px 0;
  }
  &__subtitle {
    margin-top: 33px;
    margin-bottom: 25px;
    max-width: 740px;
    @include below($notebook) {
      margin-top: 24px;
    }
  }
  &__past {
    font-size: 18px;
    color: #676974;
    margin-bottom: 44px;
    @include below($notebook) {
      margin-bottom: 66px;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    row-gap: 32px;
    justify-content: center;
    @include below($notebook) {
      gap: 16px;
    }
  }
  &__item {
    height: 105px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    color: #222222;
    padding: 15px 24px;
    font-size: 16px;
    width: calc(100% / 3 - 32px);
    @include below($notebook) {
      width: 100%;
      height: unset;
      padding: 22px 16px;
      &:nth-child(4) {
        order: 6;
      }
      &:nth-child(5) {
        order: 5;
      }
      &:nth-child(3) {
        order: 4;
      }
    }
    &.half {
      width: calc(100% / 2 - 24px);
      @include below($notebook) {
        width: 100%;
      }
    }
    &-icon {
      width: 44px;
      min-width: 44px;
      height: 44px;
    }
  }
}
</style>
