<template>
  <div class="d-comparing">
    <v-container class="d-comparing__row">
      <div class="d-comparing__td"></div>
      <div class="d-comparing__td">
        <v-icon name="logo-text-dark" class="d-comparing__td-icon" />
      </div>
      <div class="d-comparing__td head">
        {{ $t("comparing.table.titles.amazon") }}
      </div>
      <div class="d-comparing__td head">
        {{ $t("comparing.table.titles.google") }}
      </div>
      <div class="d-comparing__td head">
        {{ $t("comparing.table.titles.microsoft") }}
      </div>
    </v-container>
    <div class="d-comparing__dark">
      <v-container class="d-comparing__row">
        <div
          class="d-comparing__td left"
          v-html="$t(`comparing.table.security`)"
        />
        <div class="d-comparing__td white">{{ $t("words.high") }}</div>
        <div class="d-comparing__td">{{ $t("words.high") }}</div>
        <div class="d-comparing__td">{{ $t("words.high") }}</div>
        <div class="d-comparing__td">{{ $t("words.high") }}</div>
      </v-container>
    </div>
    <v-container class="d-comparing__row">
      <div class="d-comparing__td left" v-html="$t(`comparing.table.uptime`)" />
      <div class="d-comparing__td">> 99%</div>
      <div class="d-comparing__td">> 99%</div>
      <div class="d-comparing__td">> 99%</div>
      <div class="d-comparing__td">> 99%</div>
    </v-container>
    <div class="d-comparing__dark">
      <v-container class="d-comparing__row">
        <div class="d-comparing__td left">
          {{ $t("comparing.table.interoperability") }}
        </div>
        <div class="d-comparing__td white">
          <v-icon class="d-comparing__plus" name="plus" />
        </div>
        <div class="d-comparing__td">
          <v-icon class="d-comparing__plus" name="plus" />
        </div>
        <div class="d-comparing__td">
          <v-icon class="d-comparing__plus" name="plus" />
        </div>
        <div class="d-comparing__td">
          <v-icon class="d-comparing__plus" name="plus" />
        </div>
      </v-container>
    </div>
    <v-container class="d-comparing__row">
      <div class="d-comparing__td left">
        {{ $t("comparing.table.support") }}
      </div>
      <div class="d-comparing__td hard">{{ $t("words.notRequired") }}</div>
      <div class="d-comparing__td">
        <v-icon class="d-comparing__plus" name="plus" />
      </div>
      <div class="d-comparing__td">
        <v-icon class="d-comparing__plus" name="plus" />
      </div>
      <div class="d-comparing__td">
        <v-icon class="d-comparing__plus" name="plus" />
      </div>
    </v-container>
    <div class="d-comparing__dark">
      <v-container class="d-comparing__row">
        <div
          class="d-comparing__td left"
          v-html="$t(`comparing.table.infrastructure`)"
        />
        <div class="d-comparing__td white hard">5%</div>
        <div class="d-comparing__td">80%</div>
        <div class="d-comparing__td">80%</div>
        <div class="d-comparing__td">80%</div>
      </v-container>
    </div>
    <v-container class="d-comparing__row">
      <div
        class="d-comparing__td left"
        v-html="$t(`comparing.table.endUser`)"
      />
      <div class="d-comparing__td hard">{{ $t("words.from") }} $8</div>
      <div class="d-comparing__td">{{ $t("words.from") }} $10</div>
      <div class="d-comparing__td">{{ $t("words.from") }} $10</div>
      <div class="d-comparing__td">{{ $t("words.from") }} $10</div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "desktop-comaring",
};
</script>

<style lang="scss" scoped>
.d-comparing {
  margin-top: 50px;
  @include below($notebook) {
    display: none;
  }
  &__row {
    height: 80px;
    display: grid;
    gap: 24px;
    grid-template-columns: auto 180px 180px 180px 180px;
  }
  &__dark {
    background: #f6f6f6;
  }
  &__td {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    &.left {
      text-align: left;
      justify-content: left;
    }
    &.white {
      background: white;
    }
    &.head {
      font-weight: 600;
      color: #000000;
    }
    &-icon {
      width: 115.5px;
      height: 31.57px;
    }
    &.hard {
      font-weight: 700;
      color: #000000;
      font-size: 18px;
    }
  }
  &__plus {
    width: 24px;
    height: 24px;
  }
}
</style>
