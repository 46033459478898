<template>
  <div class="what">
    <v-container>
      <h2 class="title">{{ $t("whatDoing.title") }}</h2>
      <div class="what__content">
        <div class="what__head">
          <div class="what__icons">
            <PeopleIcon class="what__icon" />
            <PeopleIcon class="what__icon" />
            <PeopleIcon class="what__icon" />
          </div>
          <span>
            {{ $t("whatDoing.users") }}
          </span>
          <ArrowBtmLongIcon class="what__arrow-btm" />
        </div>
        <div class="what__scheme">
          <div class="what__scheme-item">
            <WhatDoingGausIcon class="what__scheme-item-icon" />
            {{ $t("whatDoing.gaussBlockchain") }}
          </div>
          <div class="what__scheme-item what__scheme-center">
            <ArrayRightLongIcon class="what__scheme-long-arr" />
            <span>
              {{ $t("whatDoing.deploy") }}
            </span>
          </div>
          <div class="what__scheme-item">
            <WhatDoingKuberIcon class="what__scheme-item-icon" />
            {{ $t("whatDoing.kubernetes") }}
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import WhatDoingGausIcon from "@/components/ui-kit/icons/WhatDoingGausIcon.vue";
import WhatDoingKuberIcon from "@/components/ui-kit/icons/WhatDoingKuberIcon.vue";
import ArrayRightLongIcon from "@/components/ui-kit/icons/ArrayRightLongIcon.vue";
import PeopleIcon from "@/components/ui-kit/icons/PeopleIcon.vue";
import ArrowBtmLongIcon from "@/components/ui-kit/icons/ArrowBtmLongIcon.vue";

export default {
  name: "WhatDoingBlock",
  components: {
    ArrowBtmLongIcon,
    PeopleIcon,
    ArrayRightLongIcon,
    WhatDoingKuberIcon,
    WhatDoingGausIcon,
  },
};
</script>

<style lang="scss" scoped>
.what {
  padding-top: 120px;
  @include below($notebook) {
    padding-top: 70px;
  }
  &__content {
    margin-top: 50px;
    @include below($notebook) {
      margin-top: 60px;
      position: relative;
    }
  }
  &__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    color: #222222;
    position: relative;
    span {
      max-width: 340px;
      margin: 0 auto;
    }
    @include below($notebook) {
      font-size: 14px;
      position: absolute;
      text-align: left;
      top: 50%;
      z-index: 2;
      flex-direction: row;
      width: 72px;
      transform: translateY(-50%);
    }
  }
  &__icons {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-bottom: 33px;
    @include below($notebook) {
      display: none;
    }
  }
  &__icon {
    width: 62px;
    height: 62px;
  }
  &__scheme {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    @include below($notebook) {
      flex-direction: column;
    }
    &-long-arr {
      @include below($notebook) {
        transform: rotate(90deg);
        width: 30%;
        margin: 0 auto;
      }
    }
    &-center {
      span {
        max-width: 200px;
        margin: 0 auto;
      }
      @include below($notebook) {
        position: relative;
        span {
          max-width: 140px;
          display: block;
          padding: 7px;
          background: white;
          width: 244px;
          z-index: 1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
        }
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 17px;
      justify-content: center;
      font-size: 24px;
      color: #222222;
      text-align: center;
      max-width: 300px;
      margin: 0 auto;
      @include below($notebook) {
        font-size: 20px;
      }
      &-icon {
        width: 288px;
        height: 319px;
        @include below($notebook) {
          width: 152px;
          height: 169.31px;
          margin: 0 auto;
        }
      }
    }
  }
  &__arrow-btm {
    width: 36px;
    height: 77px;
    position: absolute;
    left: 50%;
    bottom: -35px;
    transform: translate(-50%, 100%);
    @include below($notebook) {
      width: 16px;
      height: 23px;
      left: unset;
      right: -10px;
      top: 50%;
      bottom: unset;
      transform: translateY(-50%) translateX(100%) rotate(-90deg);
    }
  }
}
</style>
