<template>
  <div class="chat">
    <v-container>
      <h2 class="title">{{ $t("chat.title") }}</h2>
      <p class="subtitle" v-html="$t('chat.subtitle')" />
      <div class="chat__content">
        <div class="chat__item" v-for="(item, idx) in items" :key="idx">
          <div class="chat__item-title">{{ item.title }}</div>
          <div class="chat__item-desk">{{ item.desk }}</div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "ChatBlock",
  computed: {
    items() {
      if (window.innerWidth <= 1024) {
        return [
          {
            title: this.$t("chat.items.expedia.title"),
            desk: this.$t("chat.items.expedia.description"),
          },
          {
            title: this.$t("chat.items.klarna.title"),
            desk: this.$t("chat.items.klarna.description"),
          },
          {
            title: this.$t("chat.items.kayak.title"),
            desk: this.$t("chat.items.kayak.description"),
          },
          {
            title: this.$t("chat.items.wolfram.title"),
            desk: this.$t("chat.items.wolfram.description"),
          },
          {
            title: this.$t("chat.items.shop.title"),
            desk: this.$t("chat.items.shop.description"),
          },
          {
            title: this.$t("chat.items.openTable.title"),
            desk: this.$t("chat.items.openTable.description"),
          },
          {
            title: this.$t("chat.items.zapier.title"),
            desk: this.$t("chat.items.zapier.description"),
          },
          {
            title: this.$t("chat.items.speak.title"),
            desk: this.$t("chat.items.speak.description"),
          },
          {
            title: this.$t("chat.items.milo.title"),
            desk: this.$t("chat.items.milo.description"),
          },
          {
            title: this.$t("chat.items.fiscal.title"),
            desk: this.$t("chat.items.fiscal.description"),
          },
          {
            title: this.$t("chat.items.instacart.title"),
            desk: this.$t("chat.items.instacart.description"),
          },
        ];
      }
      return [
        {
          title: this.$t("chat.items.expedia.title"),
          desk: this.$t("chat.items.expedia.description"),
        },
        {
          title: this.$t("chat.items.fiscal.title"),
          desk: this.$t("chat.items.fiscal.description"),
        },
        {
          title: this.$t("chat.items.instacart.title"),
          desk: this.$t("chat.items.instacart.description"),
        },
        {
          title: this.$t("chat.items.kayak.title"),
          desk: this.$t("chat.items.kayak.description"),
        },
        {
          title: this.$t("chat.items.klarna.title"),
          desk: this.$t("chat.items.klarna.description"),
        },
        {
          title: this.$t("chat.items.milo.title"),
          desk: this.$t("chat.items.milo.description"),
        },
        {
          title: this.$t("chat.items.openTable.title"),
          desk: this.$t("chat.items.openTable.description"),
        },
        {
          title: this.$t("chat.items.shop.title"),
          desk: this.$t("chat.items.shop.description"),
        },
        {
          title: this.$t("chat.items.speak.title"),
          desk: this.$t("chat.items.speak.description"),
        },
        {
          title: this.$t("chat.items.wolfram.title"),
          desk: this.$t("chat.items.wolfram.description"),
        },
        {
          title: this.$t("chat.items.zapier.title"),
          desk: this.$t("chat.items.zapier.description"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  padding-top: 70px;
  padding-bottom: 120px;
  @include below($notebook) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .subtitle {
    margin-top: 33px;
    @include below($notebook) {
      margin-top: 24px;
    }
    max-width: 680px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 24px;
    margin: 55px -8px 0;
    justify-content: center;
    @include below($notebook) {
      margin-top: 64px;
      row-gap: 16px;
      justify-content: flex-start;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &__item {
    height: 145px;
    padding: 22px 16px;
    background: #f6f6f6;
    border-radius: 24px;
    width: calc(100% / 4 - 16px);
    @include below($notebook) {
      width: 100%;
      height: unset;
      padding: 18px 16px;
    }
    &-title {
      font-weight: 600;
      font-size: 18px;
      color: theme(current-color);
      margin-bottom: 8px;
      @include below($notebook) {
        font-size: 14px;
      }
    }
    &-desk {
      font-size: 14px;
      color: #676974;
      @include below($notebook) {
        font-size: 14px;
      }
    }
  }
}
</style>
