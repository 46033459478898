const english = {
  form: {
    title: "Feedback form",
    firstName: "First Name",
    firstNamePlaceholder: "Enter your name",
    email: "Email Address",
    emailPlaceholder: "Enter your email",
    message: "Your Message",
    messagePlaceholder: "Enter your massage",
  },
  words: {
    send: "Send",
    ok: "Ok",
    complete: "Complete",
    from: "From",
    notRequired: "Not required",
    high: "High",
    copyright: "Copyright",
  },
  promo: {
    title: "Making Big Data small & Keeping complexity behind the curtain",
  },
  percentage: {
    title:
      "50 ZB is the total amount of digitized information in the world as of 2023",
  },
  chat: {
    title: "Era Chat GPT-4",
    subtitle: `We estimate that <span>10^20 PFLOPS**</span> of additional power <br>
        <span>will be required</span> to train <span>Chat GPT-4</span>`,
    items: {
      expedia: {
        title: "Expedia",
        description:
          "Bring your trip plans to life – get there, stay there, find things to see and do",
      },
      fiscal: {
        title: "FiscalNote",
        description:
          "Provides and enables access to select market-leading, real-time data sets for legal, political, and regulatory data and information",
      },
      instacart: {
        title: "Instacart",
        description: "Order from your favorite local grocery stores",
      },
      kayak: {
        title: "KAYAK",
        description:
          "Search for flights, stays and rental cars. Get recommendations for all the places you can go within your budget",
      },
      klarna: {
        title: "Klarna Shopping",
        description: "Search and compare prices from thousands of online shops",
      },
      milo: {
        title: "Milo Family AI",
        description:
          "Giving parents superpowers to turn the manic to magic, 20 minutes each day. Ask: Hey Milo, what’s magic today?",
      },
      openTable: {
        title: "Open Table",
        description:
          "Provides restaurant recommendations, with a direct link to book",
      },
      shop: {
        title: "Shop",
        description:
          "Search for millions of products from the world’s greatest brands.",
      },
      speak: {
        title: "Speak",
        description:
          "Learn how to say anything in another language with Speak, your AI-powered language tutor",
      },
      wolfram: {
        title: "Wolfram",
        description:
          "Access computation, curated knowledge & real-time data through Wolfram|Alpha and Wolfram Language",
      },
      zapier: {
        title: "Zapier",
        description:
          "Interact with over 5000+ apps like Google Sheets, Trello, Gmail, HubSpot, Salesforce and more",
      },
    },
  },
  jobs: {
    title: `20 jobs that GPT-4 <span>can potentially replace</span>`,
    description: `<span>Our resources are required by GPT-4</span> to implement these features`,
    items: {
      "Data Entry Clerk": "Data Entry Clerk",
      "Customer Service Representative": "Customer Service Representative",
      Proofreader: "Proofreader",
      "Social Media Manager": "Social Media Manager",
      Bookkeeper: "Bookkeeper",
      Translator: "Translator",
      Copywriter: "Copywriter",
      "Market Research Analyst": "Market Research Analyst",
      "Appointment Scheduler": "Appointment Scheduler",
      Telemarketer: "Telemarketer",
      "Technical Support Analyst": "Technical Support Analyst",
      "Email Marketer": "Email Marketer",
      Transcriptionist: "Transcriptionist",
      "News Reporter": "News Reporter",
      "Travel Agent": "Travel Agent",
      Paralegal: "Paralegal",
      Recruiter: "Recruiter",
      "Content Moderator": "Content Moderator",
      Assistant: "Assistant",
      Tutor: "Tutor",
    },
  },
  whatDoing: {
    title: "What are we doing?",
    users: "Users with unused computing resources",
    gaussBlockchain: "Gauss Blockchain deployed on Kubernetes",
    kubernetes: "Large Kubernetes network",
    deploy: "Deploying a shared network",
  },
  solution: {
    title: "Our Solution",
    text: `Our team offers a ready to use, brand new technology that allows
          customers to employ unused computing power to analyze Big Data without
          investing in hardware. <span>DEKUBE is technology that provides higher data protection than any other global industry giant</span>`,
    kubernetes: `Kubernetes\n platform`,
    gaussBlockchain: `Gauss\n blockchain`,
    sharingUnused: `Sharing unused\n computing power`,
  },
  market: {
    title: "Market",
    description: `<span>We are another small player</span> in the market`,
    top: `DEKUBE accounts for <br /><span>7%</span> of the total market`,
    bottom: "93% of the market",
    bottomText: `
      Teradata, Oracle, SAP, EMC, Amazon, Microsoft, Google, VMware,
      Splunk, Alteryx, iTechArt, InData Labs, ScienceSoft, RightData ,
      Integrate.io, Oxagile, Innowise Group, IBM, HP Enterprise, Cogito
      and etc`,
  },
  comparing: {
    title: "Comparing with competitors",
    description: `Due to the GAUSS Blockchain and lesser infrastructure support costs,
        <span>DEKUBE is more effective</span> than competitors’ similar services`,
    table: {
      titles: {
        amazon: "Amazon Elastic MapReduce",
        google: "Google Cloud Platform",
        microsoft: "Microsoft Azure Analytics Services",
      },
      security: "Security",
      uptime: "Uptime service-level <br />agreement (SLA)",
      interoperability:
        "Interoperability with all leading Big Data analysis technologies",
      support: "Support of hardware infrastructure",
      infrastructure: "Infrastructure support cost<br /> (% of all costs)",
      endUser: "End-user cost of services<br/> (per unit per month)",
    },
  },
  achiev: {
    title: "DEKUBE team achievements",
    subtitle: `
        Over the past 20 years
        <span> our team developed and deployed systems </span>
        for our customers all over the world`,
    pastProjects: "Past projects include:",
    items: {
      corporate:
        "Corporate system for transmitting encrypted messages using hybrid cryptographic algorithms of symmetric and asymmetric encryption",
      hierarchical:
        "Hierarchical public key infrastructure system with the ability to use cross-certificates of external certification authorities",
      anonymization: "Anonymization system for data transfer using ToR and I2P",
      security: "Security-focused operating system",
      distributed: "Distributed information security management",
      highLoad: "High-load fault-tolerant electronic data interchange system",
      overSecure: "Over-secure user key storage system for a corporate network",
    },
  },
  modal: {
    confirm: "Confirm",
    share: "Share resources",
    connect: "Connect services",
    topText:
      "The DEKUBE system client will allow you to connect to the DEKUBE network and provide your computing resources",
    alert:
      "Please download the installation file, run it, and follow the instructions in the interface",
    text: "The distribution package is installed like any other program. Please make sure you have administrator rights before proceeding with the installation",
    btn: "Download the file",
    size: "File size",
    welcome: "Welcome to DEKUBE!",
    description:
      "Select the operating system on which you want to install the DEKUBE system client",
    windowsDescr: "You will need to download the installation file",
    linuxDescr:
      "Instructions will open in a new tab\n" +
      "with a sequence of installation commands",
    publicKey:
      "You will need the <a href='https://testnet.imgauss.com' target='_blank'>Gauss wallet</a> public key",
  },
};
export default english;
