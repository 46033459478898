<template>
  <transition name="fade">
    <div class="connect-modal" v-if="open">
      <div
        class="connect-modal__overlay"
        @click="$emit('update:open', false)"
      />
      <div class="connect-modal__content">
        <CloseIcon
          class="connect-modal__close"
          @click="$emit('update:open', false)"
        />
        <div class="connect-modal__head">
          <v-icon class="connect-modal__head-logo" name="logo-dark" />
          <div class="connect-modal__head-text">
            <template v-if="isDownloadStep"
              >{{ $t("modal.topText") }}
            </template>
            <template v-else>
              {{ $t("modal.welcome") }} <br />
              {{ $t("modal.description") }}
            </template>
          </div>
        </div>
        <div class="connect-modal__main">
          <template v-if="isDownloadStep">
            <div class="connect-modal__alert">
              <v-icon name="box" class="connect-modal__alert-icon" />
              <span>
                {{ $t("modal.alert") }}
              </span>
            </div>
            <div class="connect-modal__text">
              {{ $t("modal.text") }}
              <div v-html="$t('modal.publicKey')" />
            </div>
            <div class="connect-modal__btn-wrap">
              <a href="./DEKUBE.exe" class="connect-modal__btn btn">{{
                $t("modal.btn")
              }}</a>
            </div>
          </template>
          <template v-else>
            <div class="connect-modal__checkboxes">
              <div
                class="connect-modal__checkbox"
                v-for="item in systems"
                :key="item.value"
                :class="{ active: system === item.value }"
                @click="system = item.value"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  v-if="item.value === system"
                >
                  <rect
                    x="2.41675"
                    y="2.41667"
                    width="15.1667"
                    height="15.1667"
                    rx="7.58333"
                    stroke="#2464EB"
                    stroke-width="1.5"
                  />
                  <rect
                    x="5"
                    y="5"
                    width="10"
                    height="10"
                    rx="5"
                    fill="#2464EB"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <rect
                    x="2.41675"
                    y="2.41666"
                    width="15.1667"
                    height="15.1667"
                    rx="7.58333"
                    stroke="#7A85A2"
                    stroke-width="1.5"
                  />
                </svg>
                <div class="connect-modal__checkbox-text">
                  <div class="connect-modal__checkbox-title">
                    {{ item.title }}
                  </div>
                  <div class="connect-modal__checkbox-descr">
                    {{ item.descr }}
                  </div>
                </div>
              </div>
            </div>
            <div class="connect-modal__btn-wrap">
              <a
                href="#"
                @click="
                  system === 'windows'
                    ? (isDownloadStep = true)
                    : handleOpenLinux()
                "
                class="connect-modal__btn btn"
                >{{ $t("modal.confirm") }}</a
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import CloseIcon from "@/components/ui-kit/icons/CloseIcon.vue";
import { useI18n } from "vue-i18n";

defineEmits(["update:open"]);
defineProps({
  open: Boolean,
});

const { t } = useI18n();
const isDownloadStep = ref(false);
const system = ref("windows");
const systems = [
  {
    value: "windows",
    title: "Windows",
    descr: t("modal.windowsDescr"),
  },
  {
    value: "linux",
    title: "Linux",
    descr: t("modal.linuxDescr"),
  },
];

const handleOpenLinux = () => {
  window.open("/Setting_up_DEKUBE_system_client_Linux_en.pdf", "_blank");
};
</script>

<style lang="scss" scoped>
.connect-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 20;
  justify-content: center;
  &__overlay {
    background: rgba(5, 16, 32, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    z-index: 1;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0 0 4px rgba(83, 93, 119, 0.15),
      0 2px 18px rgba(101, 122, 154, 0.2);
    overflow: hidden;
    width: 432px;
    @include below($mobileLrg) {
      width: 90%;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    @include below($mobileLrg) {
      top: 16px;
      right: 16px;
      width: 20px;
      height: 20px;
    }
  }
  &__head {
    background: #eff6ff;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 36px 32px 32px;
    @include below($mobileLrg) {
      padding: 24px 16px 16px;
    }
    &-logo {
      width: 144px;
      height: 96px;
      text-align: center;
      margin-bottom: 22px;
      @include below($mobileLrg) {
        width: 110px;
        height: 80px;
        margin-bottom: 16px;
      }
    }
    &-text {
      text-align: center;
      color: #626575;
      font-size: 14px;
    }
  }
  &__main {
    padding: 32px 32px 44px;
    @include below($mobileLrg) {
      padding: 24px 16px 24px;
    }
  }
  &__alert {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    span {
      color: #1c59c6;
      font-weight: 700;
      font-size: 14px;
    }
    &-icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
    }
  }
  &__text {
    padding-left: 32px;
    color: #626575;
    font-size: 14px;
    margin-bottom: 48px;
    div {
      display: block;
      &::v-deep(a) {
        color: var(--blue-400, #1c59c6);
        text-decoration: underline;
      }
    }
    @include below($mobileLrg) {
      margin-bottom: 32px;
    }
  }
  &__btn-wrap {
    display: flex;
    justify-content: flex-end;
  }
  &__size {
    font-size: 14px;
    color: #626575;
    text-align: center;
  }
  &__checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 46px;
    gap: 16px;
  }
  &__checkbox {
    display: flex;
    gap: 8px;
    color: var(--gray-400, #7a85a2);
    font-size: 14px;
    cursor: pointer;
    &-text {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    > svg {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
    &.active {
      .connect-modal__checkbox-title {
        color: var(--blue-600, #2464eb);
        font-weight: 500;
      }
    }
  }
}
</style>
