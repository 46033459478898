<template>
  <div class="v-container"><slot /></div>
</template>

<script>
export default {
  name: "v-container",
};
</script>

<style lang="scss" scoped>
.v-container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 18px;
}
</style>
