import messages from "./lang";
import { createI18n } from "vue-i18n";
export let currentLocale = localStorage.getItem("lang");
document.documentElement.setAttribute("lang", currentLocale);
if (!currentLocale) currentLocale = "en";
const i18n = createI18n({
  locale: currentLocale || "en", // set locale
  fallbackLocale: "en", // set fallback locale
  globalInjection: true,
  legacy: false,
  messages,
});
export default i18n;
