<template>
  <footer class="footer">
    <v-container>
      <div class="footer__content">
        <div class="footer__social">
          <p>{{ $t("words.copyright") }} © 2023 DEKUBE</p>
          <div class="footer__items">
            <!--            <a href="https://discord.gg/JfzWHUaD" target="_blank"-->
            <!--              ><v-icon class="footer__item" name="disk"-->
            <!--            /></a>-->
            <!--            <a href="https://t.me/dekube" target="_blank"-->
            <!--              ><v-icon class="footer__item" name="tg"-->
            <!--            /></a>-->
            <a href="#" target="_blank"
              ><v-icon class="footer__item" name="twitter"
            /></a>
            <a href="#" target="_blank"
              ><v-icon class="footer__item" name="youtube"
            /></a>
          </div>
        </div>
        <v-icon class="footer__logo" name="logo-footer" />
        <div class="footer__btn-wrap">
          <button class="footer__btn" @click="activeModal = true">
            <v-icon class="footer__btn-icon" name="mail" />
            feedback
          </button>
        </div>
      </div>
    </v-container>
    <v-modal v-model:active="activeModal" />
  </footer>
</template>

<script>
import VModal from "@/components/ui-kit/v-modal.vue";

export default {
  name: "FooterBlock",
  components: { VModal },
  data() {
    return {
      activeModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 35px 0;
  background: #202126;
  @include below($notebook) {
    padding: 40px 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 145px 1fr;
    align-items: center;
    @include below($notebook) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }
  &__logo {
    width: 145px;
    height: 100px;
    @include below($notebook) {
      order: -1;
      width: 136px;
      height: 104px;
    }
  }
  &__btn {
    width: 175px;
    background: #1c59c6;
    border-radius: 8px;
    height: 44px;
    border: none;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    font-size: 18px;
    color: theme(white);
    cursor: pointer;
    transition: background 0.2s ease;
    &:hover {
      background: #194faf;
    }
    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
    &-icon {
      width: 19.2px;
      height: 14.4px;
    }
  }
  &__social {
    @include below($notebook) {
      order: 1;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      gap: 20px;
    }
    p {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 14px;
      color: theme(white);
      @include below($notebook) {
        font-weight: 400;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  &__items {
    display: flex;
    gap: 10px;
    @include below($notebook) {
      gap: 13px;
    }
  }
  &__item {
    width: 32px;
    height: 32px;
    @include below($notebook) {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
