<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="33"
    viewBox="0 0 58 33"
    fill="none"
  >
    <path
      d="M57 7L42.0843 21L37 16.2278"
      stroke="#1C59C6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42 26.8333C42 29.6868 39.7055 32 36.875 32H6.125C3.29454 32 1 29.6868 1 26.8333V6.16667C1 3.3132 3.29454 1 6.125 1H36.875C39.7055 1 42 3.3132 42 6.16667M2.07514 3L20.0414 15.482C20.9189 16.0944 22.0811 16.0944 22.9586 15.482L40.5 3"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SuccessIcon",
};
</script>

<style scoped></style>
